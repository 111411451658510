export const APP = 'PDT';
// Event Action Types
export const ADD_EVENT = "ADD_EVENT";

// Chat Action Types
export const HANDLE_FILTER_USER = "HANDLE_FILTER_USER";
export const HANDLE_SELECT_USER = "HANDLE_SELECT_USER";
export const HANDLE_SEND_MESSAGE = "HANDLE_SEND_MESSAGE";

export const SAVE_USER_INFO = `${APP}:USER:SAVE:INFO`
export const LOGOUT = `${APP}:LOGOUT`
