import {LOGOUT, SAVE_USER_INFO} from "./../../types/types";

const initialState = {}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_USER_INFO:
            return { ...action.payload };
        case LOGOUT:
            return { };
        default:
            return state;
    }
}

export default authReducer;
